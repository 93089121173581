<template>
  <div>
    <div
      class="d-flex align-items-center justify-content-between bg-primary py-2 px-4 rounded"
    >
      <h6 class="m-0 text-white">{{ $t("TOP_LEAGUES") }}</h6>
    </div>

    <!-- <a
      class="d-flex mb-2 bg-light"
      v-for="data in leaguesTable.teams"
      :key="data"
      style="cursor: pointer"
      href="upcomingevents"
    >
      <img
        :src="data.logoUrl"
        style="width: 40px; height: 40px; object-fit: cover"
      />
      <div class="bg-light py-2 px-2" style="width: 100%">
        <p class="m-0">
          {{ data.name }}
        </p>
      </div>
    </a> -->
    <table>
      <tr>
        <td class="text-center">
          <b class="m-0 p-0">{{ $t("TEAM") }}</b>
        </td>
        <td class="text-center">
          <b class="m-0 p-0">{{ $t("F") }}</b>
        </td>
        <td class="text-center">
          <b class="m-0 p-0">{{ $t("W") }}</b>
        </td>
        <td class="text-center">
          <b class="m-0 p-0">{{ $t("D") }}</b>
        </td>
        <td class="text-center">
          <b class="m-0 p-0">{{ $t("L") }}</b>
        </td>
        <td class="text-center">
          <b class="m-0 p-0">{{ $t("LAST_5") }}</b>
        </td>
        <td class="text-center">
          <b class="m-0 p-0">{{ $t("PTS") }}</b>
        </td>
        <!-- <td class="text-center">
          <b class="m-0 p-0">{{ $t("GOALS") }}</b>
        </td> -->
      </tr>

      <tr v-for="data in leaguesTable.teams" :key="data">
        <td class="text-left">
          <p class="m-0">
            {{ data.name }}
          </p>
        </td>
        <td class="text-center">
          <p class="m-0">
            {{ data.played }}
          </p>
        </td>
        <td class="text-center">
          <p class="m-0">
            {{ data.won }}
          </p>
        </td>
        <td class="text-center">
          <p class="m-0">
            {{ data.drawn }}
          </p>
        </td>
        <td class="text-center">
          <p class="m-0">
            {{ data.lost }}
          </p>
        </td>
        <td class="text-center">
          <div class="col-12 d-flex justify-content-center">
            <div class="row">
              <span
                :class="[
                  'm-0',
                  showForm(data.form)[i - 1] === 'L'
                    ? 'red'
                    : showForm(data.form)[i - 1] === 'W'
                    ? 'green'
                    : 'grey',
                ]"
                v-for="i in data.form.split('').length"
                :key="i"
              >
                {{ showForm(data.form)[i - 1] }}
              </span>
            </div>
          </div>
        </td>
        <td class="text-center">
          <p class="m-0">
            {{ data.points }}
          </p>
        </td>

        <!-- <td class="text-center">
          <p class="m-0">{{ data.lost }}: {{ data.lost }}</p>
        </td> -->
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  components: {},
  mounted() {
    let url = new URL(window.location.href);
    let param = url.searchParams.get("league_id");
    this.$http
      .get(`${process.env.VUE_APP_BACKEND_API_URL}/leagues/table`, {
        params: { league_id: param },
      })
      .then(({ data }) => {
        this.leaguesTable = data.data;
        console.log("leagues", this.leaguesTable);
      })
      .catch((err) => {
        return;
      });
  },
  data() {
    return {
      leaguesTable: {},
    };
  },
  methods: {
    showForm(val) {
      return val;
    },
  },
};
</script>

<style lang="scss">
.status {
  background-color: rgb(0, 0, 0);
  border-radius: 4px;
  margin: 2px 0px 0px 0px;
  padding: 0px 6px 3px 6px !important;
  span {
    font-size: 12px;
    color: rgb(255, 255, 255);
  }
}
a {
  font-size: 14px;
}
table {
  padding: 20px;
  font-family: "Kanit", sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-size: 12px;
}

.highlight {
  background-color: #aaffb0;
}

span.red {
  background: #ea4335;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
span.green {
  background: #3aa757;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
span.grey {
  background: #9aa0a6;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
</style>
