<template>
  <div>
    <!-- <p>Facts & Figures</p>
    <div class="row">
      <div class="col-lg-6">
        <div class="card">
          <div class="d-flex">
            <img
              src="img/news-100x100-2.jpg"
              style="width: 40px; height: 40px; object-fit: cover"
            />
            <div class="bg-light py-2 px-2" style="width: 100%">
              <p class="m-0">
                There have been over 2.5 goals scored in 5 of Wolverhampton
                Wanderers's last 6 games (Premier League).
              </p>
            </div>
          </div>
          <hr />
          <div class="card-body">
            <div class="d-flex justify-content-between pr-2">
              <div>
                <b class="card-title m-0">
                  Over 2.5

                  <span class="badge bg-success text-white">EXCELLENT</span>
                </b>
                <p class="card-title">ODDS 1.88</p>
              </div>
              <div>
                <button type="button" class="btn btn-primary mr-2">BET</button
                ><button type="button" class="btn btn-primary">
                  {{ $t("PLAYNOW") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card">
          <div class="d-flex">
            <img
              src="img/news-100x100-2.jpg"
              style="width: 40px; height: 40px; object-fit: cover"
            />
            <div class="bg-light py-2 px-2" style="width: 100%">
              <p class="m-0">
                There have been over 2.5 goals scored in 5 of Wolverhampton
                Wanderers's last 6 games (Premier League).
              </p>
            </div>
          </div>
          <hr />
          <div class="card-body">
            <div class="d-flex justify-content-between pr-2">
              <div>
                <b class="card-title m-0">
                  Over 2.5

                  <span class="badge bg-success text-white">EXCELLENT</span>
                </b>
                <p class="card-title">ODDS 1.88</p>
              </div>
              <div>
                <button type="button" class="btn btn-primary mr-2">BET</button
                ><button type="button" class="btn btn-primary">
                  {{ $t("PLAYNOW") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="row">
      <div class="col-lg-6">
        <div class="card mt-4">
          <div
            class="d-flex align-items-center justify-content-between bg-primary py-2 px-4 rounded"
          >
            <h6 class="m-0 text-white">{{ $t("LAST_GAME") }}</h6>
          </div>
          <table>
            <tr>
              <td class="text-center">
                <b class="m-0 p-0">{{ $t("HOME_TEAM") }}</b>
              </td>
              <td class="text-center">
                <b class="m-0 p-0">{{ $t("GOALS") }} / {{ $t("DATE") }} </b>
              </td>
              <td class="text-center">
                <b class="m-0 p-0">{{ $t("AWAY_TEAM") }}</b>
              </td>
            </tr>

            <tr v-for="data in h2h.fixtures" :key="data">
              <td class="text-center">
                <p
                  :class="[
                    'm-0',
                    data.homeGoals > data.awayGoals
                      ? 'text-success'
                      : data.homeGoals === data.awayGoals
                      ? ''
                      : 'text-danger',
                  ]"
                >
                  {{ data.homeTeamName }}
                </p>
              </td>
              <td class="text-center">
                <p class="m-0">{{ data.homeGoals }}-{{ data.awayGoals }}</p>
                ({{ timeReturn(data.startAt) }})
              </td>
              <td class="text-center">
                <p
                  :class="[
                    'm-0',
                    data.awayGoals > data.homeGoals
                      ? 'text-success'
                      : data.homeGoals === data.awayGoals
                      ? ''
                      : 'text-danger',
                  ]"
                >
                  {{ data.awayTeamName }}
                </p>
              </td>
            </tr>
          </table>
          <!-- <a
            class="card text-center mb-4"
            id="treeLeaves"
            v-for="data in h2h.fixtures"
            :key="data"
            ><p class="card-text text-left m-2"></p>
            <div class="d-flex justify-content-between">
              <h5 class="m-2">{{ data.awayTeamName }}</h5>
              VS
              <h5 class="m-2">{{ data.homeTeamName }}</h5>
            </div>
          </a> -->
        </div>
        <div class="card mt-4">
          <div
            class="d-flex align-items-center justify-content-between bg-primary py-2 px-4 rounded"
          >
            <h6 class="m-0 text-white">{{ $t("CHANCES_OF_WINNING") }}</h6>
          </div>
          <div id="chart">
            <div class="d-flex justify-content-center">
              <apexchart
                type="donut"
                :options="chartOptions"
                :series="series"
                :labels="labels"
                width="440px"
              ></apexchart>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card mt-4">
          <div id="chart">
            <LeagueTable />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeagueTable from "../../components2/LeagueTable.vue";

export default {
  props: {
    viewmatch: {
      type: Object,
    },
  },
  components: { LeagueTable },
  mounted() {
    console.log(this.viewmatch);
  },
  data() {
    return {
      h2h: {},
      series: [44, 55, 41],
      homeTeamId: 0,
      awayTeamId: 0,

      chartOptions: {
        chart: {
          type: "donut",
          id: "chart-id",
        },

        labels: ["Loading...", "Loading...", "Loading..."],
        colors: ["#065535", "#808080", "#F9D62E"],
      },
    };
  },
  methods: {
    setChart(val) {
      console.log(val);
      this.chartOptions.labels[0] = val.homeTeamName;
      this.chartOptions.labels[1] = "Draw";
      this.chartOptions.labels[2] = val.awayTeamName;
    },
    setTeamId(val) {
      this.homeTeamId = val.homeTeamId;
      this.awayTeamId = val.awayTeamId;
      this.$http
        .get(`${process.env.VUE_APP_BACKEND_API_URL}/fixtures/h2h`, {
          params: {
            team1: this.homeTeamId,
            team2: this.awayTeamId,
          },
        })
        .then(({ data }) => {
          console.log("setup");
          this.h2h = data.data;

          ApexCharts.exec("chart-id", "updateOptions", {
            series: [
              this.h2h.stat.team1WinPercent,
              this.h2h.stat.teamDrawPercent,
              this.h2h.stat.team2WinPercent,
            ],
          });

          console.log("h2h", this.h2h);
        })
        .catch((err) => {
          return;
        });
    },
    timeReturn(val) {
      return (
        new Date(val).toDateString() +
        " " +
        new Date(val).toTimeString().split(" ")[0].split(":")[0] +
        ":" +
        new Date(val).toTimeString().split(" ")[0].split(":")[1]
      );
    },
  },
};
</script>

<style lang="scss">
.status {
  background-color: rgb(0, 0, 0);
  border-radius: 4px;
  margin: 2px 0px 0px 0px;
  padding: 0px 6px 3px 6px !important;
  span {
    font-size: 12px;
    color: rgb(255, 255, 255);
  }
}
a {
  font-size: 14px;
}
table {
  padding: 20px;
  font-family: "Kanit", sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-size: 12px;
}
</style>
