<template>
  <div>
    <div
      class="card mb-2"
      v-for="data in predictions"
      :key="data"
      v-if="predictions != null"
    >
      <div class="d-flex">
        <img
          class="rounded"
          :src="data.user.imagePath"
          style="width: 40px; height: 40px; object-fit: cover"
        />
        <div class="bg-light py-2 px-2" style="width: 100%">
          <h6 class="m-0">{{ data.user.username }}</h6>
        </div>
      </div>
      <hr />
      <div class="card-body">
        <h6 class="card-title">
          {{ data.homeTeamName }} vs {{ data.awayTeamName }}
        </h6>
        <p class="card-text">{{ timeReturn(data.startAt) }}</p>
      </div>
      <hr />
      <!-- <div
            class="card-body"
            data-toggle="modal"
            data-target=".bd-modal-lg"
            style="cursor: pointer"
          >
            <h6
              class="card-title"
              data-toggle="modal"
              data-target=".bd-modal-lg"
              style="cursor: pointer"
            >
              {{ $t("PREDICTION") }}
            </h6>
            <p
              class="card-text"
              data-toggle="modal"
              data-target=".bd-modal-lg"
              style="cursor: pointer"
            >
              {{ data.prediction.winner }}
            </p>
          </div> -->
      <div class="card-body">
        <div class="d-flex justify-content-between pr-2">
          <h6 class="card-title">
            {{ $t("PREDICTION") }}
            <!-- <span class="badge bg-success text-white">EXCELLENT</span> -->
          </h6>
          <div>
            <h6 class="text-success text-center">
              {{ data.prediction.winner }}
            </h6>
            <!-- <span class="badge bg-success text-white">{{ data.awayTeamName}}</span> -->
          </div>
        </div>
      </div>
    </div>
    <div class="card" v-if="predictions === null">
      <div class="card-body">
        <p class="text-center">
          {{ $t("0_PREDICTION") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    predictions: {
      type: Object,
    },
  },
  mounted() {},
  data() {
    return {};
  },
  methods: {
    timeReturn(val) {
      return (
        new Date(val).toDateString() +
        " " +
        new Date(val).toTimeString().split(" ")[0].split(":")[0] +
        ":" +
        new Date(val).toTimeString().split(" ")[0].split(":")[1]
      );
    },
  },
};
</script>

<style lang="scss">
.status {
  background-color: rgb(0, 0, 0);
  border-radius: 4px;
  margin: 2px 0px 0px 0px;
  padding: 0px 6px 3px 6px !important;
  span {
    font-size: 12px;
    color: rgb(255, 255, 255);
  }
}
a {
  font-size: 14px;
}
table {
  padding: 20px;
  font-family: "Kanit", sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-size: 12px;
}
#treeLeaves {
  transition: transform 0.2s; /* Animation */
}
#treeLeaves:hover {
  transform: scale(1.03);
}
</style>
