<template>
  <div>
    <!-- Modal -->
    <div
      class="modal fade bd-modal-lg"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <!-- <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> -->
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p class="text-dark text-center">
              Event started on 11.11.2023 19:30
            </p>
            <div
              class="align-items-center justify-content-center h4 m-0 text-white text-decoration-none"
            >
              <div class="d-flex justify-content-between">
                <div class="col-lg-4">
                  <h5 class="text-center">Wolverhampton Wanderers</h5>
                </div>
                <div class="col-lg-3">
                  <div class="text-center">
                    <h5>2:1</h5>
                    <p class="text-dark mb-0">HT: 0 - 1 | FT: 2 - 1</p>
                  </div>
                  <!-- <div class="text-center">
                  <h2>
                    <span class="badge badge-primary">2 - 1</span>
                  </h2>
                  <p>HT: 0 - 1 | FT: 2 - 1</p>
                  <h6 class="text-white">11.11.2023 19:30</h6>
                </div> -->
                </div>
                <div class="col-lg-4">
                  <h5 class="text-center">Tottenham Hotspur</h5>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <p
                    class="text-dark"
                    style="position: absolute; right: 0; top: -18px"
                  >
                    Tip Score: 8.1
                  </p>
                  <span
                    class="badge badge-pill badge-success"
                    style="position: absolute; right: 0; top: 0"
                    >EXCELLENT</span
                  >
                  <h6 class="text-center">
                    Both teams will score in the match
                  </h6>
                  <p class="text-center text-dark">
                    TIPPED AT 1.64 | STAKE 100
                  </p>
                </div>
              </div>
              <div class="d-flex">
                <img
                  src="img/news-100x100-2.jpg"
                  style="width: 60px; height: 60px; object-fit: cover"
                />
                <div class="bg-light py-2 px-2" style="width: 100%">
                  <div class="d-flex justify-content-between">
                    <h6 class="m-0">smoker1</h6>
                    <h5 class="m-0 text-success">
                      TIP IS WON: 64 VIRTUAL PROFIT
                    </h5>
                  </div>
                  <div class="d-flex justify-content-between">
                    <p class="m-0 text-dark">Published 11.11.2023 18:44</p>
                    <p class="m-0 text-dark">PROFIT = STAKE * ODDS - STAKE</p>
                  </div>
                </div>
              </div>
              <hr />
              <div class="align-items-center justify-content-center">
                <p class="text-dark text-center mt-2 mb-0 p-0">
                  User Statistics (Last 6 Months) These are parameters which
                </p>
                <p class="text-secondary text-center p-0">
                  determine tip score
                </p>
              </div>
              <table>
                <tr>
                  <th class="text-center"></th>

                  <th class="text-center text-dark">{{ $t("TIPS") }}</th>
                  <th class="text-center text-dark">{{ $t("YIELD") }}</th>
                  <th class="text-center text-dark">{{ $t("TIP_SCORE") }}</th>
                </tr>
                <tr>
                  <td class="text-center text-dark">FOOTBALL</td>

                  <td class="text-center text-dark">2692</td>
                  <td class="text-center text-dark">0.4%</td>
                  <td class="text-center text-dark">0.00</td>
                </tr>
              </table>
              <p class="text-dark mt-2 text-right">
                CALCULATED TIP SCORE:
                <span class="badge badge-pill badge-success">8:1</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8 mt-4">
        <h5>Team / Tournament Expert Tips</h5>
        <p>
          Tips from Tipsters with high yield for specific tournament or team
        </p>

        <Prediction :predictions="predictions" />
      </div>
      <div class="col-lg-4">
        <MostPlayed />
      </div>
    </div>
  </div>
</template>

<script>
import MostPlayed from "../../components2/MostPlayed.vue";
import Prediction from "../../components2/Prediction.vue";

export default {
  components: { MostPlayed, Prediction },
  mounted() {
    let url = new URL(window.location.href);
    let param = url.searchParams.get("id");
    console.log(param); // outputs 'yay'
    this.$http
      .get(`${process.env.VUE_APP_BACKEND_API_URL}/predictions`, {
        params: { fixture_id: param },
      })
      .then(({ data }) => {
        this.predictions = data.data;
        console.log("predictions", this.predictions);
      })
      .catch((err) => {
        return;
      });
  },
  data() {
    return { predictions: {} };
  },
  methods: {
    timeReturn(val) {
      return (
        new Date(val).toDateString() +
        " " +
        new Date(val).toTimeString().split(" ")[0].split(":")[0] +
        ":" +
        new Date(val).toTimeString().split(" ")[0].split(":")[1]
      );
    },
  },
};
</script>

<style lang="scss">
.status {
  background-color: rgb(0, 0, 0);
  border-radius: 4px;
  margin: 2px 0px 0px 0px;
  padding: 0px 6px 3px 6px !important;
  span {
    font-size: 12px;
    color: rgb(255, 255, 255);
  }
}
a {
  font-size: 14px;
}
table {
  padding: 20px;
  font-family: "Kanit", sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-size: 12px;
}
</style>
