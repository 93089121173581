<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card mt-4">
          <div
            class="d-flex align-items-center justify-content-between bg-primary py-2 px-4 rounded"
          >
            <h6 class="m-0 text-white">{{ $t("CHANCES_OF_WINNING") }}</h6>
          </div>
          <div class="row m-0">
            <!-- {{ viewmatch }} -->
            <div
              :class="[
                'col-lg-4 card ',
                currentPrediction === 'HOME' ? 'addtip-highlight' : '',
              ]"
              id="treeLeaves"
            >
              <div
                class="card-body"
                style="cursor: pointer"
                @click="setPrediction('HOME')"
              >
                <div class="d-flex justify-content-between pr-2">
                  <h6 class="card-title m-0">
                    <div>
                      <img
                        class="card-img"
                        :src="viewmatch.homeTeamLogoUrl"
                        alt="Card image"
                        style="width: 25px; height: 25px; object-fit: cover"
                      />
                      {{ viewmatch.homeTeamName }}
                    </div>

                    <!-- <span class="badge bg-success text-white">EXCELLENT</span> -->
                  </h6>
                  <h6 class="badge bg-success text-white">
                    {{ $t("PREDICTION_RATE") }}
                    {{ h2h.stat.team1WinPercent }}%
                  </h6>
                  <!-- <h6 class="text-success">
                    {{ $t("PREDICTION_RATE") }} {{ h2h.stat.team1WinPercent }}%
                  </h6> -->
                </div>
              </div>
            </div>
            <div
              :class="[
                'col-lg-4 card',
                currentPrediction === 'DRAW' ? 'addtip-highlight' : '',
              ]"
              id="treeLeaves"
            >
              <div
                class="card-body"
                style="cursor: pointer"
                @click="setPrediction('DRAW')"
              >
                <div class="d-flex justify-content-between pr-2">
                  <h6 class="card-title m-0">
                    Draw
                    <!-- <span class="badge bg-success text-white">EXCELLENT</span> -->
                  </h6>
                  <h6 class="badge bg-success text-white">
                    {{ $t("PREDICTION_RATE") }}
                    {{ h2h.stat.teamDrawPercent }}%
                  </h6>
                  <!-- <h6 class="text-success">
                    {{ $t("PREDICTION_RATE") }} {{ h2h.stat.teamDrawPercent }}%
                  </h6> -->
                </div>
              </div>
            </div>
            <div
              :class="[
                'col-lg-4 card',
                currentPrediction === 'AWAY' ? 'addtip-highlight' : '',
              ]"
              id="treeLeaves"
            >
              <div
                class="card-body"
                style="cursor: pointer"
                @click="setPrediction('AWAY')"
              >
                <div class="d-flex justify-content-between pr-2">
                  <h6 class="card-title m-0">
                    <div>
                      <img
                        class="card-img"
                        :src="viewmatch.awayTeamLogoUrl"
                        alt="Card image"
                        style="width: 25px; height: 25px; object-fit: cover"
                      />
                      {{ viewmatch.awayTeamName }}
                    </div>

                    <!-- <span class="badge bg-success text-white">EXCELLENT</span> -->
                  </h6>
                  <h6 class="badge bg-success text-white">
                    {{ $t("PREDICTION_RATE") }}
                    {{ h2h.stat.team2WinPercent }}%
                  </h6>
                  <!-- <h6 class="text-success">
                    {{ $t("PREDICTION_RATE") }} {{ h2h.stat.team2WinPercent }}%
                  </h6> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card" v-if="currentPrediction != ''">
          <p class="card-text text-center mt-2">Predictions</p>

          <h5 class="card-title text-center">
            {{
              currentPrediction === "HOME"
                ? viewmatch.homeTeamName + " will win the match"
                : currentPrediction === "AWAY"
                ? viewmatch.awayTeamName + " will win the match"
                : "The match will be a draw"
            }}
          </h5>
          <button
            type="button"
            class="btn btn-md btn-primary mt-4"
            @click.prevent="createTip"
          >
            {{ $t("CREATE_A_TIP") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeagueTable from "../../components2/LeagueTable.vue";

export default {
  props: {
    viewmatch: {
      type: Object,
    },
  },
  components: { LeagueTable },
  mounted() {
    console.log(this.viewmatch);
  },
  data() {
    return {
      currentPrediction: "",
      h2h: {},
    };
  },
  methods: {
    setTeamId(val) {
      this.homeTeamId = val.homeTeamId;
      this.awayTeamId = val.awayTeamId;
      this.$http
        .get(`${process.env.VUE_APP_BACKEND_API_URL}/fixtures/h2h`, {
          params: {
            team1: this.homeTeamId,
            team2: this.awayTeamId,
          },
        })
        .then(({ data }) => {
          console.log("setup");
          this.h2h = data.data;

          console.log("h2h", this.h2h);
        })
        .catch((err) => {
          return;
        });
    },
    setPrediction(val) {
      this.currentPrediction = val;
    },
    createTip() {
      let url = new URL(window.location.href);
      let param = url.searchParams.get("id");
      console.log(param); // outputs 'yay'

      this.$fire({
        text: this.$t("CONFIRM_CREATE_TIP"),
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: this.$t("CREATE_TIP"),
        cancelButtonText: this.$t("CANCEL"),
      }).then((result) => {
        console.log(result);
        /* Read more about isConfirmed, isDenied below */
        if (result.value) {
          this.$http
            .post(`${process.env.VUE_APP_BACKEND_API_URL}/predictions`, {
              fixtureId: parseInt(param),
              prediction: { winner: this.currentPrediction },
            })
            .then(({ data }) => {
              this.$fire({
                text: this.$t("SUCCESS"),
                type: "success",
                timer: 3000,
              }).then((r) => {});
            })
            .catch((err) => {
              this.$fire({
                // title:this.$t("PASSWORD_DO_NOT_MATCH"),
                text: this.$t("PLEASE_LOGIN"),
                type: "warning",
                timer: 3000,
              }).then((r) => {});
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
#treeLeaves {
  transition: transform 0.2s; /* Animation */
}
#treeLeaves:hover {
  z-index: 2;
  transform: scale(1.03);
}
.addtip-highlight {
  border: 2px #00a013 solid;
  border-radius: 12px;
}
</style>
