var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"card mt-4"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between bg-primary py-2 px-4 rounded"},[_c('h6',{staticClass:"m-0 text-white"},[_vm._v(_vm._s(_vm.$t("LAST_GAME")))])]),_c('table',[_c('tr',[_c('td',{staticClass:"text-center"},[_c('b',{staticClass:"m-0 p-0"},[_vm._v(_vm._s(_vm.$t("HOME_TEAM")))])]),_c('td',{staticClass:"text-center"},[_c('b',{staticClass:"m-0 p-0"},[_vm._v(_vm._s(_vm.$t("GOALS"))+" / "+_vm._s(_vm.$t("DATE"))+" ")])]),_c('td',{staticClass:"text-center"},[_c('b',{staticClass:"m-0 p-0"},[_vm._v(_vm._s(_vm.$t("AWAY_TEAM")))])])]),_vm._l((_vm.h2h.fixtures),function(data){return _c('tr',{key:data},[_c('td',{staticClass:"text-center"},[_c('p',{class:[
                  'm-0',
                  data.homeGoals > data.awayGoals
                    ? 'text-success'
                    : data.homeGoals === data.awayGoals
                    ? ''
                    : 'text-danger' ]},[_vm._v(" "+_vm._s(data.homeTeamName)+" ")])]),_c('td',{staticClass:"text-center"},[_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(data.homeGoals)+"-"+_vm._s(data.awayGoals))]),_vm._v(" ("+_vm._s(_vm.timeReturn(data.startAt))+") ")]),_c('td',{staticClass:"text-center"},[_c('p',{class:[
                  'm-0',
                  data.awayGoals > data.homeGoals
                    ? 'text-success'
                    : data.homeGoals === data.awayGoals
                    ? ''
                    : 'text-danger' ]},[_vm._v(" "+_vm._s(data.awayTeamName)+" ")])])])})],2)]),_c('div',{staticClass:"card mt-4"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between bg-primary py-2 px-4 rounded"},[_c('h6',{staticClass:"m-0 text-white"},[_vm._v(_vm._s(_vm.$t("CHANCES_OF_WINNING")))])]),_c('div',{attrs:{"id":"chart"}},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('apexchart',{attrs:{"type":"donut","options":_vm.chartOptions,"series":_vm.series,"labels":_vm.labels,"width":"440px"}})],1)])])]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"card mt-4"},[_c('div',{attrs:{"id":"chart"}},[_c('LeagueTable')],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }