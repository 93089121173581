<template>
  <div>
    <!-- Main News Slider Start -->
    <div class="container-fluid py-3">
      <div class="container">
        <div
          class="position-relative overflow-hidden mb-3"
          style="border-radius: 14px; height: 217px"
        >
          <img
            class="img-fluid w-100 h-100"
            src="img/soccer-1.png"
            style="object-fit: cover"
          />
          <div
            class="overlay align-items-center justify-content-center h4 m-0 text-white text-decoration-none"
          >
            <h6 class="text-white">
              {{ viewmatch.homeTeamName }} VS
              {{ viewmatch.awayTeamName }} FOOTBALL BETTING TIPS & PREDICTIONS
            </h6>
            <p>{{ viewmatch.leagueName }}</p>

            <div class="d-flex justify-content-between">
              <div class="col-lg-4">
                <img
                  class="rounded mx-auto d-block"
                  :src="viewmatch.homeTeamLogoUrl"
                  style="width: 40px; height: 40px; object-fit: cover"
                />
                <h5 class="text-white text-center">
                  {{ viewmatch.homeTeamName }}
                </h5>
              </div>
              <div class="col-lg-3">
                <div class="text-center">
                  {{ timeReturn(viewmatch.startAt) }} <br />
                </div>
                <h6 class="text-white text-center">{{ $t("TODAY") }}</h6>
                <!-- <div class="text-center">
                  <h2>
                    <span class="badge badge-primary">2 - 1</span>
                  </h2>
                  <p>HT: 0 - 1 | FT: 2 - 1</p>
                  <h6 class="text-white">11.11.2023 19:30</h6>
                </div> -->
              </div>
              <div class="col-lg-4">
                <img
                  class="rounded mx-auto d-block"
                  :src="viewmatch.awayTeamLogoUrl"
                  style="width: 40px; height: 40px; object-fit: cover"
                />
                <h5 class="text-white text-center">
                  {{ viewmatch.awayTeamName }}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a
              href="#betting_tips"
              role="tab"
              data-toggle="tab"
              class="nav-link active"
            >
              {{ $t("BETTING_TIPS") }}
            </a>
          </li>
          <li class="nav-item">
            <a href="#statistics" role="tab" data-toggle="tab" class="nav-link">
              {{ $t("STATISTICS") }}
            </a>
          </li>
          <li class="nav-item">
            <a href="#addtips" role="tab" data-toggle="tab" class="nav-link">
              {{ $t("ADDTIP") }}
            </a>
          </li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane active" role="tabpanel" id="betting_tips">
            <BettingTips />
          </div>
          <div class="tab-pane" role="tabpanel" id="statistics">
            <Statistics :viewmatch="viewmatch" ref="statics" />
          </div>
          <div class="tab-pane" role="tabpanel" id="addtips">
            <AddTip :viewmatch="viewmatch" ref="addtips" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Statistics from "./viewmatch/Statistics.vue";
import BettingTips from "./viewmatch/BettingTips.vue";
import AddTip from "./viewmatch/AddTip.vue";

export default {
  components: { Statistics, BettingTips, AddTip },
  mounted() {
    let url = new URL(window.location.href);
    let param = url.searchParams.get("id");
    console.log(param); // outputs 'yay'

    this.$http
      .get(`${process.env.VUE_APP_BACKEND_API_URL}/fixtures/${param}`)
      .then(({ data }) => {
        this.viewmatch = data;
        console.log("viewmatch", this.viewmatch);
        this.$refs.statics.setChart(this.viewmatch);
        this.$refs.statics.setTeamId(this.viewmatch);
        this.$refs.addtips.setTeamId(this.viewmatch);
      })
      .catch((err) => {
        return;
      });

    // let param1 = url.searchParams.get("id");

    this.$http
      .get(`${process.env.VUE_APP_BACKEND_API_URL}/predictions`, {
        params: {
          fixtures: param,
          username: JSON.parse(localStorage.getItem("userData")).username,
        },
      })
      .then(({ data }) => {
        this.prediction = data.data;
        console.log("prediction", this.prediction);
      })
      .catch((err) => {
        return;
      });
  },
  data() {
    return {
      viewmatch: {},
      prediction: {},
    };
  },
  methods: {
    timeReturn(val) {
      return (
        new Date(val).toTimeString().split(" ")[0].split(":")[0] +
        ":" +
        new Date(val).toTimeString().split(" ")[0].split(":")[1]
      );
    },
  },
};
</script>

<style lang="scss">
.status {
  background-color: rgb(0, 0, 0);
  border-radius: 4px;
  margin: 2px 0px 0px 0px;
  padding: 0px 6px 3px 6px !important;
  span {
    font-size: 12px;
    color: rgb(255, 255, 255);
  }
}
a {
  font-size: 14px;
}
table {
  padding: 20px;
  font-family: "Kanit", sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-size: 12px;
}
</style>
